import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import prediction from '../../img/prediction.svg';
import { breakpoints, formStyles, title, button, border } from '../../utils/style';
import CommentsStaticLoadmore from '../../components/Content/Withdraw/CommentsStaticLoadmore';
import Prediction from '../../components/shared/Prediction/Prediction';

const Wrapper = styled('div')`
  ${formStyles}
  p {
    margin-bottom: 1rem;
  }
`;

const FeaturedWrapper = styled('div')`
  user-select: none;
  position: relative;
  margin: 15px 0 0;
  width: 100%;
  ${title}
  h1 {
    left: 169px;
    img {
      left: -100px;
      top: -20px;
    }
  }
  .breadcrumbs {
    position: absolute;
    z-index: 1;
    top: 20px;
    left: 30px;
  }
  .gatsby-image-wrapper {
    border-radius: ${border.borderRadius4};
    img {
      width: 100%;
    }
  }

  @media (max-width: ${breakpoints.tl}) {
    .iconImage {
      width: 30px;
    }
    h1 {
      font-size: 28px;
      left: 80px;
      top: 64px;
      img {
        left: -46px;
        top: 2px;
      }
    }
    .gatsby-image-wrapper {
      margin-bottom: 60px;
      border-radius: 8px;
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    .breadcrumbs {
      position: absolute;
      margin: 10px 0;
      top: 10px;
      left: 40px;
    }

    .gatsby-image-wrapper {
      height: 140px;
      margin: 0 15px 82px;
    }
  }
`;

const Content = styled('div')`
  position: static;
  padding: 0px 0px 30px 0px;
  margin-top: -60px;
  a {
    ${button};
    margin: 39px 0;
    width: 100%;
  }
  @media (max-width: ${breakpoints.md}) {
    padding: 0;
  }
  @media (max-width: ${breakpoints.sm}) {
    margin-top: -15px;
  }
`;

// REFACTOR
// Spread
const PredictionGame = ({ data, pageContext }) => {
  const currentPage = data.wordpressPage;
  const featuredMedia = currentPage.featured_media;
  const metaTitle =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title
      ? currentPage.yoast_meta.yoast_wpseo_title
      : `${currentPage.title}- ${process.env.SITE_NAME_SHORT}`;
  const metaDesc =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc
      ? currentPage.yoast_meta.yoast_wpseo_metadesc
      : '';
  const relatedIds = pageContext.related;
  const { breadcrumbs } = pageContext;

  return (
    <Layout>
      <SEO title={metaTitle} description={metaDesc} pathname={currentPage.path} breadcrumbs={breadcrumbs} />
      <Wrapper>
        <FeaturedWrapper>
          <Breadcrumbs breadcrumbs={breadcrumbs} page_title={currentPage.title} />
          {featuredMedia && (
            <Img
              fluid={featuredMedia.localFile.childImageSharp.fluid}
              alt={currentPage.featured_media.alt_text ? currentPage.featured_media.alt_text : currentPage.title}
            />
          )}
          <h1>
            <img className="iconImage" src={prediction} alt="predictions" />
            <span>{currentPage.title}</span>
          </h1>
        </FeaturedWrapper>
        {relatedIds.length > 0 && (
          <Content>
            {/* <Prediction /> */}
            <CommentsStaticLoadmore />
          </Content>
        )}
      </Wrapper>
    </Layout>
  );
};

export default PredictionGame;

export const pageQuery = graphql`
  query($id: String!, $related: [Int]) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
      }
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1980) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }

    allWordpressPost(filter: { wordpress_id: { in: $related } }, limit: 4) {
      edges {
        node {
          slug
          title
          excerpt
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 430) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    allWordpressWpApiMenusMenusItems(filter: { slug: { eq: "prediction" } }) {
      nodes {
        id
        name
        slug
        items {
          type_label
          title
          object_slug
          url
          path
        }
      }
    }
  }
`;
